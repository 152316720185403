<template>
  <div class='hotel'>
    <header-top />
    <el-row>
      <el-col :span="16">
        <div class="lefttop">
          <img-swiper />
          <chart />
        </div>
        <div>
          <record />
        </div>
      </el-col>
      <el-col :span="8">
        <introduce />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HeaderTop from './components/header'
import ImgSwiper from './components/imgSwiper'
import Chart from './components/chart'
import Record from './components/record'
import Introduce from './components/introduce'
export default {
  components: { HeaderTop, ImgSwiper, Chart, Record, Introduce },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.hotel {
  width: 100%;
  min-height: 100vh;
  background: url("./images/bg.png") no-repeat;
  background-size: 100% 100%;
  .el-row {
    margin-top: 11px;
    padding: 0 10px;
    .lefttop {
      display: flex;
    }
  }
}
</style>
