<template>
  <div class='introduce'>
    <div class="headlineTop">保洁记录</div>
    <ul v-if="$store.getters.hotelname.indexOf('丽水隐居画乡院落') !== -1 || $store.getters.hotelname.indexOf('欢庭原始村落') !== -1">
      <li>
        <div class="title">
          <span>
            <img src="../../images/icon_left.png" alt="" />
          </span>
          客房保洁视频
          <i><img src="../../images/icon_right.png" alt="" /></i>
        </div>
        <div class='videoboxSpecial'>
          <video src="https://air.terabits.cn/var/file/20220914.mp4" autoPlay loop muted controls></video>
        </div>
      </li>
      <li>
        <div class="title">
          <span><img src="../../images/icon_left.png" alt="" /></span>
          最近消毒视频
          <i><img src="../../images/icon_right.png" alt="" /></i>
        </div>
        <div class='videoboxSpecial'>
          <video :src="$store.getters.hotelname.indexOf('丽水隐居画乡院落') !== -1?'https://air.terabits.cn/var/file/202209141048.mp4':'http://ivatest.terabits.cn/var/file/2022082201.mp4'" autoPlay loop muted controls></video>
        </div>
      </li>
    </ul>
    <ul v-else>
      <li>
        <div class="title">
          <span>
            <img src="../../images/icon_left.png" alt="" />
          </span>
          功能介绍
          <i><img src="../../images/icon_right.png" alt="" /></i>
        </div>
        <div class='videobox'>
          <video :src="video ? video : 'http://zjiva.terabits.cn/var/file/20211230.mp4'" autoPlay loop muted></video>
        </div>
      </li>
      <li>
        <div class="title">
          <span><img src="../../images/icon_left.png" alt="" /></span>
          内容展示
          <i><img src="../../images/icon_right.png" alt="" /></i>
        </div>
        <div class='substancebox'>
          <p>
            <img src="../../images/code.png" alt="" />
          </p>
          <p>
            <img src="../../images/content.png" alt="" />
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getImg } from '@/api'

export default {
  data () {
    return {
      video: null
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      if (this.$store.getters.hotelname.indexOf('丽水隐居画乡院落') === -1) {
        getImg().then(res => {
          this.video = res.data.videoUrl
        })
      }
    }
  }
};
</script>

<style scoped lang="scss">
.introduce {
  width: 100%;
  height: 378px;
  background: url("../../images/introduce_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 1px;
  margin-left: 7px;
  ul {
    padding: 8px 7px 0;
    li {
      .title {
        display: flex;
        align-items: center;
        font-size: 7px;
        font-weight: bold;
        color: #55c6e6;
        span {
          display: block;
          width: 9px;
          height: 10px;
          margin-right: 3px;
          img {
            width: 100%;
            // height: 100%;
          }
        }
        i {
          width: 49px;
          height: 6px;
          margin-left: 3px;
          background: none;
          img {
            width: 100%;
            // height: 100%;
          }
        }
      }
      .videobox {
        width: 90%;
        height: 112px;
        border: 1px solid rgba(8, 229, 255, 0.4);
        margin: 13px auto 27px;
        padding: 1px;

        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .videoboxSpecial {
        width: 90%;
        height: 130px;
        border: 1px solid rgba(8, 229, 255, 0.4);
        margin: 13px auto 27px;
        padding: 1px;

        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .substancebox {
        width: 90%;
        display: flex;
        justify-content: space-around;
        margin: 13px auto 0;

        p {
          width: 96px;
          height: 168px;
          border: 1px solid rgba(5, 166, 184, 0.4);
          background: #303f9e;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
