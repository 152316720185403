<template>
  <div class='chart'>
    <div class="headlineTop">数据分析</div>
    <ul>
      <li>
        <div class="title">
          <span>
            <img src="../../images/icon_left.png" alt="" />
          </span>
          近一周布草更换记录
          <i><img src="../../images/icon_right.png" alt="" /></i>
        </div>
        <div id="linen"></div>
      </li>
      <li>
        <div class="title">
          <span><img src="../../images/icon_left.png" alt="" /></span>
          近一周洁具清洁
          <i><img src="../../images/icon_right.png" alt="" /></i>
        </div>
        <div id="sanitary"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getLinen, getClean } from '@/api'

export default {
  data () {
    return {
      cleanList: [],
      linenList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      const startTime = this.$moment().subtract(7, 'days').format("YYYY-MM-DD HH:mm:ss")
      const endTime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
      getClean(startTime, endTime).then(res => {
        // console.log(res, 333)
        res.data.sort((a, b) => {
          return Date.parse(a.date.replace(/-/g, "/")) - Date.parse(b.date.replace(/-/g, "/"))
        })
        this.cleanList = res.data
        // console.log(this.list)
        this.drawSanitary()
      })
      getLinen().then(res => {
        res.data.sort((a, b) => {
          return Date.parse(a.date.replace(/-/g, "/")) - Date.parse(b.date.replace(/-/g, "/"))
        })
        // console.log(res.data.data)
        if (res) {

          this.linenList = res.data
          this.drawChart()
        }
      })
    },
    // 向上取整
    ceilNumber (number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    drawChart () {
      var linenChart = this.$echarts.init(document.getElementById('linen'))
      var barWidth = 18;
      // 日期
      let xData2 = []
      // 房间数量
      let data1 = []
      // 最大值
      let data2 = []
      // 最底部数据，都为1
      let days = []
      // 合格率
      let qualifiedData = []
      if (this.$store.getters.hotelname.indexOf('丽水隐居画乡院落') !== -1) {
        xData2 = this.cycle()
        data1 = [6, 13, 15, 7, 5, 8, 5]
        data2 = [15, 15, 15, 15, 15, 15, 15]
        days = [1, 1, 1, 1, 1, 1, 1]
        qualifiedData = [100, 100, 100, 100, 100, 100, 100]
      } else if (this.$store.getters.hotelname.indexOf('欢庭原始村落') !== -1) {
        xData2 = this.cycle()
        data1 = [16, 16, 11, 21, 13, 12, 12]
        data2 = [21, 21, 21, 21, 21, 21, 21]
        days = [1, 1, 1, 1, 1, 1, 1]
        qualifiedData = [100, 100, 100, 100, 100, 100, 100]
      } else {
        this.linenList.map(el => {
          if (el.date) {
            xData2.push(this.$moment(el.date).format("MM/DD"))
          }
          if (el.roomCleanedSum) {
            data1.push(el.roomCleanedSum)
          }
          qualifiedData.push((el.roomUpStandardSum / el.roomCleanedSum * 100).toFixed(2))
          days.push(1)
        })
        var max = this.ceilNumber(Math.max.apply(null, data1))
        // console.log(count)
        this.linenList.map(() => {
          data2.push(max)
        })
      }
      var _that = this
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          itemHeight: 5,
          itemWidth: 5,
          right: '0',
          top: '0',
          textStyle: {
            color: '#fff',
            fontSize: 9
          },
          data: [
            {
              name: '房间数量',
              icon: 'rect',
              color: '#75DAFF'
            },
            {
              name: '合格率',
              icon: 'rect',
              color: '#FFCC00'
            }
          ]
        },
        grid: {
          bottom: 20,
          top: 30,
          right: '15%'
          // top: '19%',
        },
        xAxis: {
          data: xData2,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#fff',
              fontSize: 9,
            },
            margin: 8, //刻度标签与轴线之间的距离。
          },
        },
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            // max: max,
            // interval: 300,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                // fontSize: 14,
              },
            },
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 100,
            interval: 20,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 14,
              },
            },
          }
        ],
        series: [
          { // 上半截柱子
            name: '',
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            z: 0,
            itemStyle: {
              color: '#626262',
              opacity: .36,
              label: {
                show: false
              },
            },
            tooltip: {
              show: false
            },
            data: data2
          },
          { //下半截柱子
            name: '房间数量',
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            itemStyle: { //lenged文本
              opacity: 1,
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#5FB8F1' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#89DAF9' // 100% 处的颜色
                }], false)
              }
            },
            data: data1
          },
          { //上半截柱子顶部圆片
            name: "",
            type: "pictorialBar",
            symbolSize: [barWidth, 9],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              color: '#313131 ',
              opacity: 1,
            },
            label: {
              show: false,
              position: 'top',
              fontSize: 16,
              color: '#fff',
            },
            tooltip: {
              show: false
            },
            "data": data2
          },
          { //下半截柱子顶部圆片
            name: "房间数量",
            type: "pictorialBar",
            symbolSize: [barWidth, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
              opacity: 1,
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#75DAFF' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#75DAFF' // 100% 处的颜色
                }], false)
              }
            },
            label: {
              show: false,
            },
            symbolPosition: "end",
            data: data1
          },
          { //下半截柱子底部圆片
            name: "房间数量",
            type: "pictorialBar",
            symbolSize: [barWidth, 9],
            symbolOffset: [0, 5],
            z: 12,
            itemStyle: {
              opacity: 1,
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#8BDBF9' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#8BDBF9' // 100% 处的颜色
                }], false)
              }
            },
            tooltip: {
              show: false
            },
            "data": days
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [24, 8],
            symbolOffset: [0, 6],
            z: 11,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: '#3ACDC5',
                borderWidth: 2
              }
            },
            tooltip: {
              show: false
            },
            data: days
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [31, 11],
            symbolOffset: [0, 9],
            z: 10,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: "#02c3f1",
                borderWidth: 2
              }
            },
            tooltip: {
              show: false
            },
            data: days
          },
          {
            name: '合格率',
            type: 'line',
            yAxisIndex: 1,
            data: qualifiedData,
            zlevel: 100,
            itemStyle: {
              color: '#FFCC00'
            },
            lineStyle: {
              width: 1
            }
          }
        ]
      };
      option && linenChart.setOption(option)
      window.addEventListener("resize", function () {
        linenChart.resize();
      })
    },
    drawSanitary () {
      var sanitaryChart = this.$echarts.init(document.getElementById('sanitary'))
      var barWidth = 18;
      let xData2 = [];
      let data1 = [];
      let data2 = [];
      let days = []
      // 合格率
      let qualifiedData = []
      if (this.$store.getters.hotelname.indexOf('丽水隐居画乡院落') !== -1) {
        xData2 = this.cycle()
        data1 = [6, 13, 15, 7, 5, 8, 5]
        data2 = [15, 15, 15, 15, 15, 15, 15]
        days = [1, 1, 1, 1, 1, 1, 1]
        qualifiedData = [100, 100, 100, 100, 100, 100, 100]
      } else if (this.$store.getters.hotelname.indexOf('欢庭原始村落') !== -1) {
        xData2 = this.cycle()
        data1 = [16, 16, 11, 21, 13, 12, 12]
        data2 = [21, 21, 21, 21, 21, 21, 21]
        days = [1, 1, 1, 1, 1, 1, 1]
        qualifiedData = [100, 100, 100, 100, 100, 100, 100]
      } else {
        this.cleanList.map(el => {
          if (el.date) {
            xData2.push(this.$moment(el.date).format("MM/DD"))
          }
          if (el.roomCleanedSum) {
            data1.push(el.roomCleanedSum)
          }
          qualifiedData.push((el.roomUpStandardSum / el.roomCleanedSum * 100).toFixed(2))
          days.push(1)
        })
        var max = this.ceilNumber(Math.max.apply(null, data1))
        // console.log(count)
        this.cleanList.map(() => {
          data2.push(max)
        })
      }
      var _that = this
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          itemHeight: 5,
          itemWidth: 5,
          right: '0',
          top: '0',
          textStyle: {
            color: '#fff',
            fontSize: 9
          },
          data: [
            {
              name: '房间数量',
              icon: 'rect',
              color: '#75DAFF'
            },
            {
              name: '合格率',
              icon: 'rect',
              color: '#FFCC00'
            }
          ]
        },
        grid: {
          bottom: 20,
          top: 30,
          right: '15%'
          // top: '19%',
        },
        xAxis: {
          data: xData2,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#fff',
              fontSize: 9,
            },
            margin: 8, //刻度标签与轴线之间的距离。
          },
        },
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            // max: max,
            // interval: 300,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                // fontSize: 14,
              },
            },
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 100,
            interval: 20,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 14,
              },
            },
          }
        ],
        series: [
          { // 上半截柱子
            name: '',
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            z: 0,
            itemStyle: {
              color: '#626262',
              opacity: .36,
              label: {
                show: false
              },
            },
            tooltip: {
              show: false
            },
            data: data2
          },
          { //下半截柱子
            name: '房间数量',
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            itemStyle: { //lenged文本
              opacity: 1,
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#0584AB' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#0CCFD5' // 100% 处的颜色
                }], false)
              }
            },
            data: data1
          },
          { //上半截柱子顶部圆片
            name: "",
            type: "pictorialBar",
            symbolSize: [barWidth, 9],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              color: '#313131 ',
              opacity: 1,
            },
            label: {
              show: false,
              position: 'top',
              fontSize: 16,
              color: '#fff',
            },
            tooltip: {
              show: false
            },
            "data": data2
          },
          { //下半截柱子顶部圆片
            name: "房间数量",
            type: "pictorialBar",
            symbolSize: [barWidth, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
              opacity: 1,
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#25EBEC' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#25EBEC' // 100% 处的颜色
                }], false)
              }
            },
            label: {
              show: false,
            },
            symbolPosition: "end",
            data: data1
          },
          { //下半截柱子底部圆片
            name: "房间数量",
            type: "pictorialBar",
            symbolSize: [barWidth, 9],
            symbolOffset: [0, 5],
            z: 12,
            itemStyle: {
              opacity: 1,
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#0CCCD3' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#0CCCD3' // 100% 处的颜色
                }], false)
              }
            },
            tooltip: {
              show: false
            },
            "data": days
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [24, 8],
            symbolOffset: [0, 6],
            z: 11,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: '#3ACDC5',
                borderWidth: 2
              }
            },
            tooltip: {
              show: false
            },
            data: days
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [31, 11],
            symbolOffset: [0, 9],
            z: 10,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: "#02c3f1",
                borderWidth: 2
              }
            },
            tooltip: {
              show: false
            },
            data: days
          },
          {
            name: '合格率',
            type: 'line',
            yAxisIndex: 1,
            data: qualifiedData,
            zlevel: 100,
            itemStyle: {
              color: '#FFCC00'
            },
            lineStyle: {
              width: 1
            }
          }
        ]
      };
      option && sanitaryChart.setOption(option)
      window.addEventListener("resize", function () {
        sanitaryChart.resize();
      })
    },
    // 拼接参数
    concatParams (params) {
      let paramsStr = ''
      for (var k in params) {
        paramsStr += k + '=' + params[k] + '&'
      }
      paramsStr = '?' + paramsStr.substr(0, paramsStr.length - 1)
      // console.log(paramsStr)
      return paramsStr
    },
    // 获取一周日期
    cycle () {
      // let time = this.moment().format('MM/DD')
      let weekArr = []
      for (let i = 6; i >= 0; i--) {
        let tempTime = this.$moment().subtract(i, 'days').format('MM/DD')
        weekArr.push(tempTime)
      }
      return weekArr
    }
  }
};
</script>

<style scoped lang="scss">
.chart {
  width: 190px;
  height: 235px;
  background: url("../../images/bar.png") no-repeat;
  background-size: 100% 100%;
  margin: 6px 0 0 6px;

  ul {
    padding: 8px 7px 0;
    li {
      .title {
        display: flex;
        align-items: center;
        font-size: 7px;
        font-weight: bold;
        color: #55c6e6;
        span {
          display: block;
          width: 9px;
          height: 10px;
          margin-right: 3px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        i {
          width: 49px;
          height: 6px;
          margin-left: 3px;
          background: none;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      #linen,
      #sanitary {
        height: 100px;
      }
    }
  }
}
</style>
