<template>
  <div class='header'>
    <div class="left">
      <h3>莲宿区健康民宿数字化监管平台</h3>
    </div>
    <p class='center'>{{$store.getters.hotelname}}</p>
    <div class="right">
      <div class="goback" @click="goback()">
        <i class="el-icon-caret-left"></i>
        <span>返回平台</span>
      </div>
      <div class="time">
        <p>{{$moment(time).format('HH:mm:ss')}}</p>
        <p>{{$moment().format('YYYY-MM-DD')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
var interval

export default {
  data () {
    return {
      time: this.$moment(),
    };
  },
  created () {
    interval = setInterval(() => this.time = new Date(), 1000);
  },
  mounted () {

  },
  destroyed () {
    clearInterval(interval)
  },
  methods: {
    goback () {
      // const type = localStorage.getItem('type')
      // if (type) {
      // window.open(`http://ldiva.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // } else {
      window.open(`https://zjiva.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // window.open(`http://ivatest.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // }
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  height: 72px;
  background: url("../../images/header.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .left {
    display: flex;
    padding-left: 14px;
    h3 {
      font-size: 36px;
      font-weight: 500;
      background-image: linear-gradient(135deg, #ccfbf9, #b3cdfd);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
  .center {
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    left: 50%;
    // margin-left: -200px;
    width: 400px;
    text-align: center;
  }
  .right {
    display: flex;
    align-items: center;
    font-size: 8px;
    font-weight: bold;
    color: #29ddfe;

    .goback {
      margin-right: 25px;
      cursor: pointer;

      span {
        margin-left: 2px;
      }
      .el-icon-caret-left {
        color: #ffd614;
        font-size: 10px;
      }
    }

    .time {
      font-weight: 400;
      margin-right: 11px;

      p:first-child {
        margin-bottom: 3px;
      }

      p:last-child {
        font-size: 5px;
      }
    }
  }
}
</style>
