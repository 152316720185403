<template>
  <div class='record'>
    <div class="headlineTop">最近消毒记录</div>
    <div class="recordlist">
      <el-row class="titlerow">
        <el-col :span="5">项目</el-col>
        <el-col :span="8">房间</el-col>
        <el-col :span="8">时间</el-col>
        <el-col :span="3">结果</el-col>
      </el-row>
      <div
        class="swiper swiper_record"
        v-if="recordList.length>0"
      >

        <div class="swiper-wrapper">
          <el-row
            class="contentrow swiper-slide"
            v-for="(item,index) in recordList"
            :key="index"
          >
            <el-col :span="5"><span :style="{background: item.type === 1 ? '#FFCC00' : item.type === 2 ? '#FF9100' : '#7DFF00'}"></span>{{item.type === 1 ? '洁具洗消' : item.type === 2 ? '布草更换' : '杯具洗消'}}</el-col>
            <el-col
              :span="8"
              style="color:#FFCC00"
            >{{item.roomId}}</el-col>
            <el-col
              :span="8"
              style="color:#FFCC00"
            >{{item.gmtCreate}}</el-col>
            <el-col
              :span="3"
              :style="{color:item.upstandardStatus?'#7DFF00':'red'}"
            >{{item.upstandardStatus?'合格':'不合格'}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotelRecord } from '@/api'
import 'swiper/swiper.scss';
import Swiper, { Autoplay, Pagination } from 'swiper';
Swiper.use([Autoplay, Pagination])
export default {
  data () {
    return {
      recordList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      const start = this.$moment().subtract(6, 'days').format("YYYY-MM-DD")
      const stop = this.$moment().format("YYYY-MM-DD")
      getHotelRecord(start, stop).then(res => {
        // console.log(res.data)
        this.recordList = res.data
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.recordList && this.recordList.length > 7) {
          new Swiper('.swiper_record', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
          // this.setState({
          //   mySwiper: mySwiper
          // })
        }
        if (this.recordList && this.recordList.length <= 7) {
          new Swiper('.swiper_record', {
            // autoplay: {
            //   delay: 0,
            //   stopOnLastSlide: false,
            //   disableOnInteraction: false,
            //   pauseOnMouseEnter: true
            // },
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })


    }
  }
};
</script>

<style scoped lang="scss">
.record {
  width: 100%;
  height: 122px;
  background: url("../../images/record.png") no-repeat;
  background-size: 100% 100%;
  margin: 16px 0 7px;
  position: relative;
  padding-top: 1px;
  .recordlist {
    padding: 18px 30px 7px 25px;
    .titlerow {
      font-size: 7px;
      font-weight: 500;
      color: #2fddfd;
      text-align: center;
      margin-bottom: 5px;
    }
    .contentrow {
      font-size: 7px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      .el-col {
        span {
          display: inline-block;
          width: 5px;
          height: 5px;
          background: #ffcc00;
          border-radius: 50%;
          margin-right: 2px;
        }
      }
    }
    .swiper_record {
      height: 80px;
      overflow: hidden;
      .swiper-slide {
        height: 12px;
        // line-height: 1;
        display: flex;
        align-items: center;
        // justify-content: center;
      }
      .swiper-slide:nth-child(2n) {
        background-color: rgba(48, 123, 226, 0.5);
      }
    }
  }
}
</style>
