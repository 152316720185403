<template>
  <div class='leftimgs'>
    <div class='total'>
      <p>消毒间数量：<span>{{dataList.roomCount}}</span></p>
      <p>房间数量：<span>{{dataList.braceletRoomSum}}</span></p>
      <p>布草总数：<span>{{dataList.textileSum}}</span></p>
      <p>运行天数：<span>{{dataList.runtime}}</span></p>
    </div>
    <div class="imgswiper">
      <div class="swiper swiper_img" v-if="imgList.length>0">

        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in imgList" :key="index">
            <img :src="item" alt="">
          </div>
        </div>

      </div>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
  </div>
</template>

<script>
import { getCount, getCupmsg, getImg } from '@/api'
import 'swiper/swiper.scss';
import Swiper, { Autoplay, Pagination } from 'swiper';
Swiper.use([Autoplay, Pagination])
export default {
  data () {
    return {
      dataList: {},
      imgList: [],
      mySwiper: null
    };
  },
  created () {
    this.init()
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.initSwiper()
    // })
  },
  methods: {
    init () {
      getCount().then(res => {
        // console.log(res.data)
        // const { textileSum, braceletRoomSum } = res.data
        // this.dataList.textileSum = textileSum
        // this.dataList.braceletRoomSum = braceletRoomSum
        getCupmsg().then(result => {
          // const { roomCount, runtime } = res.data
          // this.dataList.roomCount = roomCount
          // this.dataList.runtime = runtime
          this.dataList = { ...res.data, ...result.data }
        })
      })

      getImg().then(res => {
        this.imgList = JSON.parse(res.data.bannerUrl)
        // console.log(this.imgList)
        this.initSwiper()

      })

    },
    initSwiper () {
      // console.log(this.imgList)
      this.$nextTick(() => {
        new Swiper('.swiper_img', {
          autoplay: {
            delay: 0,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          },
          // direction: 'vertical',
          slidesPerView: 'auto',
          loop: true,
          speed: 3000,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
        })
      })

      // if (this.state.rankList && this.state.rankList.length > 0) {


      // }

    }
  }
};
</script>

<style scoped lang="scss">
.leftimgs {
  width: 295px;
  // margin: ;
  .total {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 3px;

    p {
      font-size: 8px;
      font-weight: bold;
      color: #ffffff;
      margin-right: 13px;
      white-space: nowrap;
      span {
        font-size: 13px;
        color: #55c6e6;
        // margin-left: .625rem;
      }
    }
  }

  .imgswiper {
    width: 100%;
    height: 216px;
    background: url("../../images/img.png") no-repeat;
    background-size: 100% 100%;
    padding: 7px 0 0;

    .swiper_img {
      width: 272px;
      height: 204px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .swiper-pagination {
      height: 8px;
      display: flex;
      justify-content: center;
      margin-top: 2px;
      ::v-deep .swiper-pagination-bullet {
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: white;
        margin-right: 6px;
      }
      // display: flex;
      // justify-content: center;
      // margin-top: 5px;
      // width: 100%;
      // .swiper-pagination-bullet {
      //   display: block;
      //   width: 3px;
      //   height: 3px;
      //   border-radius: 50%;
      //   background-color: white;
      //   margin-right: 6px;
      // }

      ::v-deep .swiper-pagination-bullet-active {
        background: #2ea7e0;
      }
    }
  }
}
</style>
